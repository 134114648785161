<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoBellCollection",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoBellCollection",
				row2: {},
				data: {
					data: []
				}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			}
		},
		methods: {
			submitHero(e) {
				if (e && e.btnLoading()) return;
				BOGen.apirest('/' + Page.Name, {
					data: this.row2,
					type: 'updateHero'
				}, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.hero-info')
						setTimeout(() => { $("#collapseExample").hide() }, 3000);
						this.refreshData()
					}
				}, "POST");
			},
			endDrag() {
				BOGen.apirest('/' + Page.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			showEdit() {
				if ($("#collapseExample").css("display") == "none") $("#collapseExample").show()
				else $("#collapseExample").hide()
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row">
			<div class="col-12" v-if="!$route.params.id">
				<div class="card">
					<VForm @resp="submitHero" method="post">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title mb-0">{{ObjectName}}</h5>
							</div>
							<hr>
							<h5 class="card-title">Hero Image</h5>
							<div class="row">
								<div class="col-md-8">
									<div class="wrap_slider_img">
										<img :src="uploader(row2.shi_image_desktop)" class="img-responsive" />
										<div class="slider_name">
											<p>Hero Image</p>
										</div>
										<a href="javascript:;" v-if="page.moduleRole('Update')" @click="showEdit" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
									</div>
								</div>
							</div>
							<div class="row collapse mt-4" id="collapseExample">
								<div class="row"> <div class="hero-info col-12"></div> </div>
								<div class="row">
									<div class="col-md-4 mb-3">
										<BoField name="shi_image_desktop" mandatory>
											<Uploader :param="{thumbnail:true}" name="shi_image_desktop" v-model="row2.shi_image_desktop"
												type="hero_static" uploadType="cropping">
											</Uploader>
										</BoField>
									</div>
									<div class="col-md-8 mb-3">
										<div class="row">
											<div class="col-md-12">
												<div class="panel_group">
													<div class="panel_heading">Heading</div>
													<div class="panel_body">
														<div class="row">
															<div class="col-md-9">
																<BoField name="shi_title_header_en" v-model="row2.shi_title_header_en"></BoField>
															</div>
															<div class="col-md-9">
																<BoField name="shi_title_header_id" v-model="row2.shi_title_header_id"></BoField>
															</div>
															<div class="col-md-2">
																<BoField :attr="{type:'color'}" name="shi_title_header_color"
																	v-model="row2.shi_title_header_color"></BoField>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-12">
														<div class="panel_group">
															<div class="panel_heading">Sub-Heading</div>
															<div class="panel_body">
																<div class="row">
																	<div class="col-md-9">
																		<div class="form-group">
																			<BoField name="shi_title_subheader_en" v-model="row2.shi_title_subheader_en">
																			</BoField>
																		</div>
																	</div>

																	<div class="col-md-9">
																		<BoField name="shi_title_subheader_id" v-model="row2.shi_title_subheader_id">
																		</BoField>
																	</div>
																	<div class="col-md-2">
																		<div class="form-group">
																			<label class="control-label">Color </label>
																			<input class="form-control" type="color" id="favcolor" name="favcolor"
																				v-model="row2.shi_title_subheader_color">
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-4 mt-3">
												<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</VForm>
				</div>
			</div>

			<div class="col-12" v-if="!$route.params.id">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title mb-0">Collection
								<span class="card-subtitle">Drag image to move the position.</span>
							</h5>
						</div>
						<hr>

						<div class="row">
							<draggable class="col-md-10" v-model="data.data" tag="div" @end="endDrag">
								<div class="item-list collection border-bottom" v-for="(v,k) in data.data" :key="k">
									<div class="row">
										<div class="col-md-8">
											<h5 class="card-title">{{v.ac_title_en}}<span class="label label-info"
													v-if="v.ac_is_featured=='Y'">Featured</span></h5>
											<div class="item-desc" v-html="v.ac_desc_en"></div>
										</div>
										<div class="col-md-4">
											<div class="product-img">
												<img :src="uploader(v.ac_image)" alt="prod">
												<div class="pro-img-overlay">
													<router-link v-if="page.moduleRole('Update')" class="bg-info" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
													<a href="javascript:;" v-if="page.moduleRole('Remove')" class="bg-danger" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
												</div>
												<span class="label label-success" v-if="v.ac_is_active=='Y'">Active</span>
												<span class="label label-danger" v-else>Inactive</span>
											</div>
										</div>
									</div>
								</div>
							</draggable>
						</div>
						<div class="row" v-if="NotFound">
							<div class="col-12 text-center">
								<h3 class="tc">{{NotFound}}</h3>
							</div>
						</div>
						<div class="row" v-if="data.data===false">
							<div class="col-12 text-center">
								<LoadingSpinner light></LoadingSpinner>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="$route.params.id">
				<VForm @resp="submitForm" method="post">
					<div class="card">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
							</div>
							<hr>
							<div class="row">
								<div class="col-12 info"></div>
								<div class="col-md-4">
									<BoField name="ac_image" mandatory>
										<Uploader :param="{thumbnail:true}" name="ac_image" v-model="row.ac_image" type="collection"
											uploadType="cropping"></Uploader>
									</BoField>
									<!-- <BoField name="ac_image_featured" mandatory>
										<Uploader :param="{thumbnail:true}" name="ac_image_featured" v-model="row.ac_image_featured"
											type="collection_featured" uploadType="cropping"></Uploader>
									</BoField>
									<div class="card border">
										<div class="card-body">
											<BoField name="ac_subscribe_phone" v-model="row.ac_subscribe_phone" :attr="{type:'Number'}" placeholder='62856xxxxxxxx'></BoField>
											<BoField name="ac_subscribe_message" class="mb-2">
												<textarea v-model="row.ac_subscribe_message" :placeholder="fields.ac_subscribe_message" class="form-control"></textarea>
											</BoField>
										</div>
									</div> -->
								</div>
								<div class="col-md-8">
									<div class="row">
										<div class="col-8">
											<BoField name="ac_title_en" v-model="row.ac_title_en"
												:attr="{placeholder:'e.g Frizzante Dolce Rosso Lambrusco'}"></BoField>
											<BoField name="ac_title_id" v-model="row.ac_title_id" :attr="{placeholder:'e.g Cabernet Merlot'}">
											</BoField>
										</div>
										<div class="col-12">
											<BoField name="ac_desc_en">
												<CKEditor type='noImage' name="ac_desc_en" v-model="row.ac_desc_en" v-bind="validation('ac_desc_en')">
												</CKEditor>
											</BoField>
										</div>
										<div class="col-12">
											<BoField name="ac_desc_id">
												<CKEditor type='noImage' name="ac_desc_id" v-model="row.ac_desc_id" v-bind="validation('ac_desc_id')">
												</CKEditor>
											</BoField>
										</div>

										<div class="col-8">
											<BoField name="ac_button_title_en" v-model="row.ac_button_title_en"
												:attr="{placeholder:'e.g Get It Now'}"></BoField>
										</div>

										<div class="col-8">
											<BoField name="ac_button_title_id" v-model="row.ac_button_title_id"
												:attr="{placeholder:'e.g Get It Now'}"></BoField>
										</div>
										<div class="col-8">
											<BoField name="ac_is_active">
												<div class="row">
													<div class="col-2">
														<radio name="ac_is_active" v-model="row.ac_is_active" option="Y" :attr="validation('ac_is_active')">Active</radio>
													</div>
													<div class="col-2">
														<radio name="ac_is_active" v-model="row.ac_is_active" option="N">Inactive</radio>
													</div>
												</div>
											</BoField>
										</div>
										<div class="col-8">
											<BoField name="ac_is_featured">
												<div class="row">
													<div class="col-2">
														<radio name="ac_is_featured" v-model="row.ac_is_featured" option="Y" :attr="validation('ac_is_featured')">Active</radio>
													</div>
													<div class="col-2">
														<radio name="ac_is_featured" v-model="row.ac_is_featured" option="N">Inactive</radio>
													</div>
												</div>
											</BoField>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="d-flex justify-content-end">
								<button type="submit" class="btn btn-rounded btn-info btn-loading">Submit</button>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>